import { ContentPost } from '../components/content/content-post-model';
import React from 'react';
import ProfileImage from '../components/account/ProfileImage';

/**
 * Utility method to create a hyperlink JSX element with a strong tag and custom class.
 * 
 * @param text - The text to be displayed within the strong tag.
 * @param link - The URL to which the link should point.
 * @param className - The class to be applied to the strong tag.
 * @returns A JSX element representing the hyperlink.
 */
export const createHyperlink = (text: string, link: string, className: string = ''): JSX.Element => {
  return (
    <a href={link} className="text-powder-bloo">
      <strong className={className}>{text}</strong>
    </a>
  );
};


export const createFigCaption = (text: string): JSX.Element => {
  return (
    <div>
    <figcaption className="text-center text-sm text-gray-500 mt-2">
      {text}
    </figcaption>
    </div>
  );
};

const formatDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const createAnchorTag = (linkTarget: string, hover?: boolean) => {
  return (
    <a href={linkTarget} className="no-underline absolute top-2 right-2">
      {/* <>^</> */}
      <img src={hover? "https://storage.googleapis.com/assestsbookiebrawler/icons/link-icon-blue.png": "https://storage.googleapis.com/assestsbookiebrawler/icons/link-icon-light-blue.png"}></img>
      {/* <button className="ctaButton">Learn More</button> */}
    </a>
  );
};



export const createATag = (linkTarget: string, text: string) => {
  return (
    <a href={"/"+linkTarget} className="no-underline">
<button className="ctaButton">{text}</button>
</a>
  );
};

export function formatDollarAmount(amount: number): string {
  // Handle invalid numbers
  if (isNaN(amount) || amount === null || amount === undefined) {
      return '$0';
  }

  // Determine the sign (positive or negative)
  const sign = amount < 0 ? '-' : '';

  // Take the absolute value and round to nearest dollar
  const roundedAmount = Math.round(Math.abs(amount));

  // Format the rounded amount with commas
  const formattedAmount = roundedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Return with the appropriate sign and no decimal places
  return `${sign}$${formattedAmount}`;
}



const imageUrlMap: { [key: string]: string } = {
  "BookieBrawler Team": "https://storage.googleapis.com/assestsbookiebrawler/logos/brawler-circle-logo.png",
  "default": ""
};

export const createFormattedDateParagraph = (date: Date, author: string): JSX.Element => {
  const imageUrl = imageUrlMap[author] || imageUrlMap["default"];
  
  return (
    <div className="py-4 text-white text-opacity-75 flex items-center">
      <ProfileImage imageUrl={imageUrl} size="40px"/>
      <div className="ml-3">
        <div className="text-[16px] font-bold text-white">{author}</div>
        <div className="text-[14px]">{formatDate(date)}</div>
      </div>
    </div>
  );
};

export const renderPostImage = (post: ContentPost | null): JSX.Element | null => {
  if (!post || !post.image) {
    return null;
  }

  return (
    <div className="flex justify-center">
      <div className="relative w-full max-w-[1000px]">
        <img
          className="w-full h-auto max-w-[1000px] min-w-[335px] light-border rounded-lg"
          src={post.image.imageUrl}
          alt={post.image.title}
        />
        {createFigCaption(post.image.source ?? "")}
      </div>
    </div>
  );
};